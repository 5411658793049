<template>
  <div class="card">
    <div class="card-header border-0">
      <div class="row align-items-center">
        <div class="col-4">
          <h3 class="mb-0 text-uppercase">investments</h3>
        </div>
        <div class="col-8 text-right">
          <button
            v-if="$store.getters['auth/user'].is_super"
            type="button"
            @click="modals.newInvestModal = true"
            class="btn btn-sm btn-warning text-uppercase"
          >
            +
          </button>
          <button
            v-for="source in sources"
            :key="source"
            type="button"
            @click="filter(source)"
            :class="{ 'btn-success': source == selectedSource }"
            class="btn btn-sm btn-primary text-uppercase"
          >
            {{ source }}
          </button>
        </div>
      </div>
    </div>
    <!-- <div class="table-responsive">
      <base-table thead-classes="thead-light" :data="user.active_bonds">
        <template slot="columns">
          <th>Amount</th>
          <th>active_on</th>
          <th>maturity</th>
          <th>source</th>
        </template>

        <template slot-scope="{row}">
          <td>{{row.invested_amount | currency}}</td>
          <td>{{row.active_on | date}}</td>
          <td>{{row.maturity | date}}</td>
          <td>{{row.source}}</td>
        </template>
      </base-table>
    </div>-->

    <div class="table-responsive">
      <table class="table table-bordered">
        <thead class="thead-light">
          <tr>
            <th></th>
            <th>active_on</th>
            <th>maturity</th>
            <th>Days to mature</th>
            <th>source</th>
            <th>Amount</th>
            <th>Life</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, index) in rows" :key="row.uuid">
            <td>{{ index + 1 }}</td>
            <td>{{ row.adt }}</td>
            <td>{{ row.mdt }}</td>
            <td>{{ row.days_to_mature }}</td>
            <td>{{ row.source }}</td>
            <td>{{ row.invested_amount | currency }}</td>
            <td>
              <small>{{ row.life }}%</small>
              <base-progress
                class="pt-0"
                type="success"
                :showPercentage="false"
                :height="8"
                :value="row.life"
              ></base-progress>
            </td>
            <td>
              <span
                class="badge badge-primary pointer mr-1"
                @click="terminate(row)"
                >terminate</span
              >
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th colspan="5">Total</th>
            <th>{{ totalAmount | currency }}</th>
          </tr>
        </tfoot>
      </table>
    </div>

    <modal
      :show.sync="modals.newInvestModal"
      modal-classes="modal-dialog-centered modal-lg"
    >
      <template slot="header">
        <h3 class="modal-title" id="exampleModalLabel">Add new investment</h3>
      </template>
      <div>
        <div class="table-responsive">
          <form @submit.prevent="submitNewInvest">
            <label for>Investment amount</label>
            <input
              type="number"
              required
              v-model="newInvest.invested_amount"
              class="form-control"
            />
            <button class="btn btn-primary mt-3" type="submit">Submit</button>
          </form>
        </div>
      </div>
      <template slot="footer">
        <base-button type="secondary" @click="modals.newInvestModal = false"
          >Close</base-button
        >
      </template>
    </modal>
  </div>
</template>

<script>
export default {
  props: {
    user: Object,
  },
  data() {
    return {
      modals: {
        newInvestModal: false,
      },
      selectedSource: "all",
      rows: [],
      sources: ["all", "new", "referral", "referral_pay", "reinvest"],
      newInvest: {
        user_uuid: "",
        invested_amount: "",
      },
    };
  },

  mounted() {
    this.newInvest.user_uuid = this.user.uuid;
    this.rows = this.user.active_bonds;
  },

  computed: {
    totalAmount() {
      let total = 0;
      this.rows.forEach((element) => {
        total += element.invested_amount;
      });
      return total;
    },
  },

  methods: {
    filter(source) {
      this.selectedSource = source;

      if (source == "all") {
        this.rows = this.user.active_bonds;
        return;
      }
      let sorted = [];
      this.user.active_bonds.forEach((element) => {
        if (element.source == source) {
          sorted.push(element);
        }
      });
      this.rows = sorted;
    },
    submitNewInvest(e) {
      e.preventDefault();
      api.invest.newInvestment(this.newInvest).then((res) => {
        this.$emit("refresh");
        this.modals.newInvestModal = false;
      });
    },

    terminate(row) {
      // console.log(row);
      if (confirm("Confirm termination?")) {
        let msg = prompt("Enter reason");
        if (msg) {
          axios
            .post("/investments/terminate/" + row.uuid, {
              msg: msg,
            })
            .then((res) => {
              console.log(res.data);
            });
        }
      }
    },
  },
};
</script>