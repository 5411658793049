<template>
  <div class>
    <div class="card">
      <div class="card-header border-0">
        <div class="row align-items-center">
          <div class="col">
            <h3 class="mb-0 text-uppercase">Pending requests</h3>
          </div>
        </div>
      </div>
      <div class="table-responsive">
        <base-table thead-classes="thead-light" :data="user.pending_bonds">
          <template slot="columns">
            <th>Date</th>
            <th>Amount</th>
            <th></th>
            <th></th>
          </template>

          <template slot-scope="{row}">
            <td>{{row.created_at}}</td>
            <td>{{row.invested_amount | currency}}</td>
            <td>
              <span
                class="badge badge-primary pointer"
                @click="statusUpdate(row.uuid, 'approved')"
              >Approve</span>
            </td>
            <td>
              <span
                class="badge badge-info pointer"
                @click="statusUpdate(row.uuid, 'rejected')"
              >Reject</span>
            </td>
          </template>
        </base-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    user: Object
  },
  methods: {
    statusUpdate(uuid, status) {
      if (confirm("Confirm action?")) {
        this.saving();
        api.invest.statusUpdate(uuid, { status: status }).then(res => {
          this.saved();
          this.$emit("refresh");
        });
      }
      return false;
    }
  }
};
</script>