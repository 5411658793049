<template>
  <div class="card" v-if="show">
    <div class="card-header border-0">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0">Payout requests</h3>
        </div>
        <div class="col text-right">
          <!-- <a href="#!" class="btn btn-sm btn-primary">See all</a> -->
        </div>
      </div>
    </div>

    <div class="table-responsive" v-if="items.rows && items.rows.length > 0">
      <base-table thead-classes="thead-light" :data="items.rows">
        <template slot="columns">
          <th>Date</th>
          <th>Name</th>
          <!-- <th>Email</th> -->
          <th>Amount</th>
          <th>method</th>
        </template>

        <template slot-scope="{row}">
          <th scope="row">{{row.cdt}}</th>
          <td class="text-uppercase">
            <router-link :to="'/investor/' + row.user.uuid">{{row.user.name}}</router-link>
          </td>
          <!-- <td>{{row.user.email}}</td> -->
          <td>{{row.amount}}</td>
          <td>
            <badge type="info">{{row.method}}</badge>
          </td>
        </template>
      </base-table>
    </div>
    <div v-else class="p-4">No records</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [],
      loading: false,
      show: false
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      api.payout.pending().then(res => {
        this.items = res.data.data;
        this.show = true;
      });
    },
    openModal(row) {
      console.log(row);
      this.$router.push("/investor/" + row.user_uuid);
    }
  }
};
</script>
