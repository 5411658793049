<template>
  <div v-if="dataLoaded">
    <base-header type="gradient-info" class="pb-6 pb-8 pt-5 pt-md-8">
      <button
        v-for="status in statuses"
        :key="status"
        @click="loadReqs(status); selectedStatus = status"
        class="btn btn-primary text-uppercase mb-2"
        :class="{ 'btn-success': (status == selectedStatus) }"
      >
        <small>{{ status }}</small>
      </button>

      <button @click="resetFilter()" class="btn btn-info text-uppercase mb-2">
        <small>Reset filter</small>
      </button>

      <div class="row mt-3">
        <div class="col-xl-4 col-lg-6">
          <div class="card card-stats mb-4 mb-xl-0" show-footer-line="true">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">Total investors</h5>
                  <span class="h2 font-weight-bold mb-0">{{ items.investors }}</span>
                </div>
              </div>
              <p class="mt-3 mb-0 text-sm"></p>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-6">
          <div class="card card-stats mb-4 mb-xl-0" show-footer-line="true">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">Total investment value</h5>
                  <!-- <span class="h2 font-weight-bold mb-0">{{ items.investedAmount }}</span> -->
                  <span class="h2 font-weight-bold mb-0">{{ investmentTotal | currency }}</span>
                </div>
              </div>
              <p class="mt-3 mb-0 text-sm"></p>
            </div>
          </div>
        </div>
      </div>

      <div class="card mt-4">
        <div class="card-body">
          <div class="row">
            <div class="col-md-4">
              <div>
                <label for>Filter by</label>
                <select @change="filterItemsFn()" v-model="filterForm.col" class="form-control">
                  <option value="active_on">Active date</option>
                  <option value="maturity">Maturity date</option>
                </select>
              </div>
            </div>
            <div class="col-md-4">
              <div>
                <label for>Month</label>
                <select
                  @change="filterItemsFn()"
                  v-model="filterForm.month"
                  name
                  id
                  class="form-control"
                >
                  <option :value="i" v-for="(val,i) in getMonths()" :key="i">{{ val }}</option>
                </select>
              </div>
            </div>
            <div class="col-md-4">
              <div>
                <label for>year</label>
                <select
                  @change="filterItemsFn()"
                  v-model="filterForm.year"
                  name
                  id
                  class="form-control"
                >
                  <option :value="val" v-for="(val,i) in getYears()" :key="i">{{ val }}</option>
                </select>
              </div>
            </div>
          </div>
          <!-- <pre>
            {{ items }}
          </pre>-->
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-bordered">
              <thead class="thead-light">
                <tr>
                  <th></th>
                  <th>Name</th>
                  <th>Mobile</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Source</th>
                  <th>Active</th>
                  <th>Mature</th>
                  <th>Days to mature</th>
                  <th>Life</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in filteredItems" :key="row.uuid">
                  <td width="2%">{{ index+1 }}</td>
                  <td class="text-uppercase">
                    <router-link :to="'/investor/' + row.user.uuid">{{row.user.name}}</router-link>
                  </td>
                  <td>{{row.user.mobile}}</td>
                  <td>{{row.invested_amount | currency}}</td>
                  <td>
                    <badge type="success">{{row.status}}</badge>
                  </td>
                  <td>
                    <badge type="info">{{row.source}}</badge>
                  </td>
                  <td>{{row.adt}}</td>
                  <td>{{row.mdt}}</td>
                  <td>{{row.days_to_mature}}</td>
                  <td>
                    <small>{{ row.life }}%</small>
                    <base-progress
                      class="pt-0"
                      type="success"
                      :showPercentage="false"
                      :height="8"
                      :value="row.life"
                      :title="row.life + '%'"
                    ></base-progress>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataLoaded: false,
      selectedStatus: "new",
      items: [],
      statuses: ["new", "referral", "referral_pay", "reinvest"],
      filterForm: {
        col: "active_on",
        month: this.getCurrentMonth(),
        year: this.getCurrentYear()
      },
      filteredItems: []
    };
  },

  mounted() {
    this.loadReqs(this.selectedStatus);
    const dt = new Date("2019-10-10 00:00:00");
    console.log(dt.getFullYear());
  },

  computed: {
    investmentTotal() {
      let total = 0;
      this.filteredItems.forEach(el => {
        total += el.invested_amount;
      });
      return total;
    }
  },

  methods: {
    loadReqs(selectedStatus) {
      this.dataLoaded = false;
      this.saving();
      api.invest.source(selectedStatus).then(res => {
        this.items = res.data.data;
        this.filteredItems = this.items.investments;
        this.dataLoaded = true;
        this.saved();
      });
    },

    filterItemsFn() {
      // console.log(1);
      this.filteredItems = [];
      this.items.investments.forEach(el => {
        let dt = new Date(el[this.filterForm.col]);
        if (
          dt.getFullYear() == this.filterForm.year &&
          dt.getMonth() == this.filterForm.month
        ) {
          this.filteredItems.push(el);
        }
      });
    },

    resetFilter() {
      this.filteredItems = this.items.investments;
      this.filterForm = {
        col: "active_on",
        month: this.getCurrentMonth(),
        year: this.getCurrentYear()
      };
    }
  }
};
</script>
