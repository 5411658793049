<template>
  <div>
    <h3>Amount: {{ payout.amount }}</h3>
    <table class="table table-bordered" style="margin-top:30px">
      <tr>
        <th>Name</th>
        <th>Return</th>
        <th>Balance</th>
        <th></th>
      </tr>
      <tr v-for="val in projects" :key="val.id">
        <td>{{ val.name }}</td>
        <td>{{ val.monthly_return }}</td>
        <td>{{ parseInt(val.monthly_return) - parseInt(getProjectInvestTotal(val.investments)) }}</td>
        <td>
          <button class="button info" @click="assignProject(val)">Assign project</button>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    payout: Object
  },
  data() {
    return {
      projects: []
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      api.bonds.getProjects().then(res => {
        this.projects = res.data.data;
      });
    },
    getProjectInvestTotal(investments) {
      let sum = 0;
      investments.forEach(element => {
        sum += parseInt(element.monthly_return);
      });
      return sum;
    },
    assignProject(project_uuid) {
      this.saving();
      api.payout
        .reinvest(this.payout.uuid, project_uuid.uuid, {
          user_uuid: this.payout.user_uuid
        })
        .then(res => {
          this.$emit("close");
          this.saved();
        });
    }
  }
};
</script>