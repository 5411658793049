<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">
        <div class="card-header bg-transparent pb-5">
          <div class="text-muted text-center mt-2 mb-3">
            <small>Sign in</small>
          </div>
        </div>
        <div v-if="prelogin" class="card-body px-lg-5 py-lg-5">
          <form role="form" @submit.prevent="submit">
            <base-input
              class="input-group-alternative"
              placeholder="Password"
              type="password"
              addon-left-icon="ni ni-lock-circle-open"
              v-model="form.password"
              autofocus
            ></base-input>

            <div class="text-center">
              <base-button
                :disabled="$store.getters['utils/saving']"
                nativeType="submit"
                type="primary"
                class="my-4"
              >
                <div v-if="$store.getters['utils/saving']">Loading</div>
                <div v-else>Sign in</div>
              </base-button>
            </div>
          </form>
        </div>
        <div v-else class="card-body px-lg-5 py-lg-5">
          <form role="form" @submit.prevent="tfa">
            <base-input
              class="input-group-alternative mb-3"
              placeholder="Email"
              addon-left-icon="ni ni-email-83"
              v-model="form.email"
              autofocus
            ></base-input>

            <div class="text-center">
              <base-button
                :disabled="$store.getters['utils/saving']"
                nativeType="submit"
                type="primary"
                class="my-4"
              >
                <div v-if="$store.getters['utils/saving']">Loading</div>
                <div v-else>Sign in</div>
              </base-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import api from "@/api/index";
import axios from "axios";

export default {
  data() {
    return {
      key: 0,
      form: {
        email: "",
        password: ""
      },
      prelogin: false
    };
  },
  mounted() {
    if (process.env.VUE_APP_ENV == "local") {
      this.form.email = "admin@apartlease.com";
      this.form.password = "445566";
    }
  },
  methods: {
    tfa(e) {
      e.preventDefault();
      this.saving();
      axios.post("/auth/prelogin", { email: this.form.email }).then(res => {
        if (res.data.ok == 1) {
          this.prelogin = true;
        } else {
          alert("Invalid email");
        }
        this.saved();
      });
    },
    submit(e) {
      e.preventDefault();
      this.saving();
      let self = this;
      api.auth
        .login(this.form)
        .then(res => {
          if (res.data.access_token) {
            // axios.defaults.headers.common["Authorization"] =
            //   "Bearer " + res.data.access_token;
            /* this.$store.commit("auth/SET_TOKEN", {
              jwt: res.data
            }); */
            this.$store.dispatch("auth/login", res.data).then(() => {
              // eslint-disable-next-line
              console.log("Auth complete");
              // this.$router.push("dashboard");
              this.$emit("success");

              // document.location = "/dashboard";
            });
            /* this.$store.dispatch("auth/getMe").then(() => {
              this.$router.push("dashboard");
              console.log("Got me");
            }); */
            this.key++;
          } else {
            alert("Incorrect login");
          }
          this.saved();
        })
        .catch(err => {
          // eslint-disable-next-line
          console.log(err);
          this.saved();
        });
    }
  }
};
</script>

<style>
</style>
