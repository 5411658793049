<template>
  <div class="card" v-if="show">
    <div class="card-header border-0">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0">Investment requests</h3>
        </div>
        <div class="col text-right">
          <router-link to="/investors" class="btn btn-sm btn-primary">See all</router-link>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table thead-classes="thead-light" :data="items.investments">
        <template slot="columns">
          <th>Date</th>
          <th>Name</th>
          <th>Email</th>
          <th>Amount</th>
          <th>Status</th>
        </template>

        <template slot-scope="{row}">
          <th scope="row">{{row.cdt}}</th>
          <td class="text-uppercase">
            <router-link :to="'/investor/' + row.user.uuid">{{row.user.name}}</router-link>
          </td>
          <td>{{row.user.email}}</td>
          <td>{{row.invested_amount}}</td>
          <td>
            <badge type="info">{{row.status}}</badge>
          </td>
          <!-- <td>
            <i
              class="fas fa-arrow-up text-success mr-3"
              :class="row.bounceRateDirection === 'up' ? 'text-success': 'text-danger'"
            ></i>
            {{row.bounceRate}}
          </td>-->
        </template>
      </base-table>
    </div>
  </div>
</template>
<script>
export default {
  name: "page-visits-table",
  data() {
    return {
      items: [],
      selectedStatus: "pending",
      show: false
    };
  },

  mounted() {
    this.loadReqs(this.selectedStatus);
  },

  methods: {
    loadReqs(selectedStatus) {
      this.saving();
      api.invest.status(selectedStatus).then(res => {
        this.items = res.data.data;
        this.saved();
        this.show = true;
      });
    }
  }
};
</script>
<style>
</style>
