<template>
  <div>
    <base-header type="gradient-info" class="pb-6 pb-8 pt-5 pt-md-8">
      <button @click="add()" class="btn btn-primary text-uppercase mb-2">
        <small>Add</small>
      </button>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="card">
        <div class="card-body">
          <table class="table table-bordered">
            <tr>
              <th>Name</th>
              <th>Remarks</th>
              <th></th>
            </tr>
            <tr v-for="row in roles" :key="row.id">
              <td>{{ row.role }}</td>
              <td>{{ row.remarks }}</td>
              <th>
                <span @click="initEdit(row)" class="badge badge-primary pointer">Edit</span>
              </th>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <modal :show.sync="modals.add" modal-classes="modal-dialog-centered modal-lg">
      <div>
        <div class="content">
          <h3>Add/edit role</h3>
          <form action @submit.prevent="submit">
            <div class="row mb-3">
              <div class="col">
                <label for>Name</label>
                <input required type="text" v-model="form.role" class="form-control" />
              </div>
              <div class="col">
                <label for>Remarks</label>
                <input type="text" v-model="form.remarks" class="form-control" />
              </div>
            </div>
            <button class="btn btn-primary" type="submit">Submit</button>
          </form>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modals: {
        add: false
      },
      form: {},
      userRoles: [],
      roles: [],
      mode: 1
    };
  },
  mounted() {
    this.init();
    this.getRoles();
    this.initForm();
  },
  methods: {
    init() {
      axios.get("/roles").then(res => {
        this.roles = res.data.data;
      });
    },
    initEdit(row) {
      this.form = row;
      this.mode = 2;
      this.modals.add = true;
    },
    submit(e) {
      e.preventDefault();
      this.saving();
      if (this.mode == 1) {
        axios.post("/roles", this.form).then(res => {
          this.init();
          this.modals.add = false;
          this.saved();
        });
      }
      if (this.mode == 2) {
        axios.put("/roles/" + this.form.id, this.form).then(res => {
          this.init();
          this.modals.add = false;
          this.saved();
        });
      }
    },
    add() {
      this.initForm();
      this.modals.add = true;
    },
    getRoles() {
      axios.get("/users/roles").then(res => {
        this.userRoles = res.data.data;
      });
    },
    initForm() {
      this.form = {
        role: "",
        remarks: ""
      };
    }
  }
};
</script>