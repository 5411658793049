<template>
  <div>
    <CashbackRequests @init="init()" :user="user.uuid" />
    <div class="card" v-if="row">
      <div class="card-header border-0">
        <div class="row align-items-center">
          <div class="col-4">
            <h3 class="mb-0 text-uppercase">Cashback</h3>
          </div>
          <div class="col-8 text-right"></div>
        </div>
      </div>
      <div class="card-body">
        <div class="rounded border p-3">
          <h4>Add payment</h4>
          <form @submit.prevent="submit">
            <div class="row mb-3">
              <div class="col-md">
                <label for="amount">Amount</label>
                <input
                  type="number"
                  step="any"
                  id="amount"
                  class="form-control"
                  required
                  v-model="form.amount"
                />
              </div>
              <div class="col-md">
                <label for="remarks">Remarks</label>
                <input
                  type="text"
                  id="remarks"
                  class="form-control"
                  v-model="form.remarks"
                />
              </div>
              <div class="col-md">
                <label for="currency">Currency</label>
                <select
                  name=""
                  v-model="form.currency"
                  id=""
                  class="form-control"
                >
                  <option value="MVR">MVR</option>
                  <option value="USD">USD</option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-md">
                <label>
                  <input
                    type="file"
                    id="file"
                    ref="file"
                    required
                    v-on:change="handleFileUpload()"
                  />
                </label>
              </div>
              <div class="col-md">
                <button class="btn btn-primary" type="submit">Submit</button>
              </div>
            </div>
          </form>
        </div>

        <!-- <pre>
          {{ row }}
        </pre> -->

        <table
          class="table table-bordered mt-5"
          v-if="row.payments && row.payments.length > 0"
        >
          <thead class="thead-light">
            <tr>
              <th>Amount</th>
              <th>Remarks</th>
              <th>Date</th>
              <th>File</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in row.payments" :key="index">
              <td>{{ row.currency }} {{ row.amount }}</td>
              <td>{{ row.remarks }}</td>
              <td>{{ row.created_at }}</td>
              <td>
                <a
                  :href="row.doc_url"
                  target="_blank"
                  class="btn btn-primary btn-sm"
                  >Open</a
                >
              </td>
              <td>
                <button
                  class="btn btn-danger btn-sm"
                  @click="deleteFile(row.uuid)"
                >
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th>{{ total }}</th>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import CashbackRequests from "@/views/Dashboard/CashbackRequests";
export default {
  components: {
    CashbackRequests,
  },
  props: {
    user: Object,
  },
  data() {
    return {
      row: {},
      form: {
        amount: "",
        remarks: "",
        file: "",
        currency: "MVR",
      },
    };
  },
  mounted() {
    // console.log(this.user);
    this.init();
  },
  computed: {
    total() {
      let t = 0;
      if (this.row.payments && this.row.payments.length > 0) {
        this.row.payments.forEach((element) => {
          t = t + parseFloat(element.amount);
        });
      }
      return t;
    },
  },
  methods: {
    init() {
      axios.get("/cashbacks/get/" + this.user.uuid).then((res) => {
        this.row = res.data.data;
      });
    },
    handleFileUpload() {
      this.form.file = this.$refs.file.files[0];
    },
    submit(e) {
      e.preventDefault();
      let formData = new FormData();
      formData.append("cb_uuid", this.row.uuid);
      formData.append("user_uuid", this.user.uuid);
      formData.append("amount", this.form.amount);
      formData.append("currency", this.form.currency);
      formData.append("file", this.form.file);
      formData.append("remarks", this.form.remarks);
      axios
        .post("/cashbacks/addPayment", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.init();
        });
    },

    deleteFile(uuid) {
      if (confirm("Confirm delete?")) {
        axios.delete("/cashbacks/deleteCbPayment/" + uuid).then(() => {
          this.init();
        });
      }
    },
  },
};
</script>