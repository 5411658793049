<template>
  <div>
    <base-header type="gradient-info" class="pb-6 pb-8 pt-5 pt-md-8">
      <button class="btn btn-primary" @click="init()">Reload</button>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="card">
        <div class="card-body">
          <h3 class="text-uppercase mb-3">Monthly projection</h3>
          <bar-chart
            v-if="showBarChart"
            :height="350"
            ref="barChart"
            :chart-data="redBarChart.chartData"
          ></bar-chart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as chartConfigs from "@/components/Charts/config";
import BarChart from "@/components/Charts/BarChart";
import axios from "axios";

export default {
  components: {
    BarChart
  },
  data() {
    return {
      dates: [],
      showBarChart: false,
      redBarChart: {
        chartData: {
          labels: [],
          datasets: [
            {
              label: "Revenue",
              data: [],
              backgroundColor: "#2dce89"
            },
            {
              label: "Payouts",
              data: [],
              backgroundColor: "#f5365c"
            }
          ]
        }
      }
    };
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      axios.get("/projection").then(res => {
        this.dates = res.data;
        this.initredBarChart();
      });
    },

    initredBarChart() {
      this.redBarChart.chartData.labels = this.dates.months;
      this.redBarChart.chartData.datasets[0].data = this.dates.projectReturns;
      this.redBarChart.chartData.datasets[1].data = this.dates.monthPayouts;
      this.showBarChart = true;
    }
  }
};
</script>
