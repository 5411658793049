<template>
  <div v-if="dataloaded">
    <base-header type="gradient-info" class="pb-6 pb-8 pt-5 pt-md-8">
      <div class="row">
        <div class="col-xl-4 col-lg-6">
          <stats-card
            title="Total"
            type="gradient-red"
            :sub-title="rows.length"
            class="mb-4 mb-xl-0"
          ></stats-card>
        </div>
        <div class="col-xl-4 col-lg-6">
          <stats-card
            title="Sum"
            type="gradient-red"
            :sub-title="returnsSum | currency"
            class="mb-4 mb-xl-0"
          ></stats-card>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-bordered">
              <thead class="thead-light">
                <tr>
                  <th></th>
                  <th>Name</th>
                  <th>Pending returns</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in rows" :key="index">
                  <td width="2%">{{ index+1 }}</td>
                  <td class="text-uppercase">
                    <router-link :to="'/investor/' + row.user.uuid">{{row.user.name}}</router-link>
                  </td>
                  <td>{{row.pending_payout | currency}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataloaded: false,
      rows: []
    };
  },

  computed: {
    returnsSum() {
      let sum = 0;
      this.rows.forEach(el => {
        sum += parseFloat(el.pending_payout);
      });
      return sum;
    }
  },

  mounted() {
    api.payout.eligiblePayout().then(res => {
      this.rows = res.data.data;
      this.dataloaded = true;
    });
  }
};
</script>