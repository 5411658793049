<template>
  <div v-if="dataLoaded">
    <base-header type="gradient-info" class="pb-6 pb-8 pt-5 pt-md-8">
      <h1>Matured investments</h1>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-bordered">
              <thead class="thead-light">
                <tr>
                  <th></th>
                  <th>Name</th>
                  <th>Mobile</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Source</th>
                  <th>Active</th>
                  <th>Mature</th>
                  <th>Life</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in rows" :key="row.uuid">
                  <td width="2%">{{ index + 1 }}</td>
                  <td class="text-uppercase">
                    <router-link
                      v-if="row.user"
                      :to="'/investor/' + row.user.uuid"
                      >{{ row.user.name }}</router-link
                    >
                  </td>
                  <td>
                    <span v-if="row.user">{{ row.user.mobile }}</span>
                  </td>
                  <td>{{ row.invested_amount | currency }}</td>
                  <td>
                    <badge type="success">{{ row.status }}</badge>
                  </td>
                  <td>
                    <badge type="info">{{ row.source }}</badge>
                  </td>
                  <td>{{ row.adt }}</td>
                  <td>{{ row.mdt }}</td>
                  <td>
                    <small>{{ row.life }}%</small>
                    <base-progress
                      class="pt-0"
                      type="success"
                      :showPercentage="false"
                      :height="8"
                      :value="row.life"
                      :title="row.life + '%'"
                    ></base-progress>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th colspan="3">Total</th>
                  <th>{{ sum | currency }}</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      rows: [],
      dataLoaded: false,
      sum: 0,
    };
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      this.dataLoaded = false;
      axios.get("/investments/matured").then((res) => {
        this.rows = res.data.data;
        let self = this;
        this.total();
        setTimeout(() => {
          self.dataLoaded = true;
        }, 500);
        // this.dataLoaded = true;
      });
    },

    total() {
      let sum = 0;
      this.rows.forEach((el) => {
        sum += el.invested_amount;
      });
      this.sum = sum;
    },
  },
};
</script>
