<template>
  <div v-if="dataLoaded">
    <base-header type="gradient-info" class="pb-6 pb-8 pt-5 pt-md-8">
      <h1 @click="init">Cash balance</h1>
    </base-header>

    <!-- <pre>
        {{ rows }}
    </pre> -->

    <div class="container-fluid mt--7">
      <div class="card">
        <div class="card-body">
          <div class="mb-2">
            <button class="btn btn-sm btn-primary" @click="exportCsv()">
              Export csv
            </button>
          </div>
          <div class="table-responsive">
            <table class="table table-bordered">
              <thead class="thead-light">
                <tr>
                  <th></th>
                  <th>Name</th>
                  <th>NID</th>
                  <th>Cash in</th>
                  <th>Cash out</th>
                  <th>Balance</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in rows.investors" :key="row.user_uuid">
                  <td width="2%">{{ index + 1 }}</td>
                  <td class="text-uppercase">
                    <router-link :to="'/investor/' + row.user_uuid">{{
                      row.name
                    }}</router-link>
                  </td>
                  <td>{{ row.nid }}</td>
                  <td>{{ row.cash }}</td>
                  <td>{{ row.paid }}</td>
                  <td>{{ row.balance }}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th>{{ rows.sumCash }}</th>
                  <th>{{ rows.sumPaid }}</th>
                  <th>{{ rows.sumBalance }}</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Papa from "papaparse";
export default {
  data() {
    return {
      rows: [],
      exportData: [],
      dataLoaded: false,
    };
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      this.dataLoaded = false;
      axios.get("/investments/cashBalance").then((res) => {
        this.rows = res.data.data;
        this.dataLoaded = true;
        this.initExportData(res.data.data.investors);
      });
    },
    initExportData(rows) {
      this.exportData = [];
      rows.forEach((row) => {
        let app = {
          name: row.name,
          nid: row.nid,
          cash: row.cash,
          paid: row.paid,
          balance: row.balance,
        };
        this.exportData.push(app);
      });
    },
    exportCsv() {
      let fileName = `CashBalance.csv`;
      let csvContent = Papa.unparse(this.exportData);
      var encodedUri = encodeURI("data:text/csv;charset=utf-8," + csvContent);
      //   window.open(encodedUri);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", fileName);
      document.body.appendChild(link); // Required for FF

      link.click();
    },
  },
};
</script>
