<template>
  <div v-if="dataLoaded">
    <base-header type="gradient-info" class="pb-6 pb-8 pt-5 pt-md-8">
      <!-- <button
        v-for="status in statuses"
        :key="status"
        @click="selectedStatus = status; loadReqs();"
        class="btn btn-primary text-uppercase mb-2"
        :class="{ 'btn-success': (status == selectedStatus) }"
      >
        <small>{{ status }}</small>
      </button>-->

      <div class="row mt-3">
        <div class="col-xl-4 col-lg-6">
          <div class="card card-stats mb-4 mb-xl-0" show-footer-line="true">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">
                    Total
                  </h5>
                  <span class="h2 font-weight-bold mb-0">{{
                    items.total
                  }}</span>
                </div>
              </div>
              <p class="mt-3 mb-0 text-sm"></p>
            </div>
          </div>
        </div>
      </div>

      <div>
        <form @submit.prevent="loadReqs">
          <div class="row mt-5">
            <div class="col-3">
              <select v-model="searchForm.year" class="form-control">
                <option v-for="year in years" :key="year" :value="year">
                  {{ year }}
                </option>
              </select>
            </div>
            <div class="col-3">
              <select v-model="searchForm.month" class="form-control">
                <option
                  v-for="(month, index) in monthNames"
                  :key="index"
                  :value="index + 1"
                >
                  {{ month }}
                </option>
              </select>
            </div>
            <div class="col-2">
              <button type="submit" class="btn btn-info">Go</button>
            </div>
          </div>
        </form>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-bordered">
              <thead class="thead-light">
                <tr>
                  <th></th>
                  <th>Date</th>
                  <th>Name</th>
                  <th>Mobile</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Source</th>
                  <th>Cmt</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in items.rows" :key="row.uuid">
                  <td width="2%">{{ index + 1 }}</td>
                  <td>{{ row.cdt }}</td>
                  <td class="text-uppercase">
                    <router-link :to="'/investor/' + row.user.uuid">{{
                      row.user.name
                    }}</router-link>
                  </td>
                  <td>{{ row.user.mobile }}</td>
                  <td>{{ row.amount | currency }}</td>
                  <td>
                    <badge type="success">{{ row.status }}</badge>
                  </td>
                  <td>
                    <badge type="info">{{ row.method }}</badge>
                  </td>
                  <td>{{ row.payment_ref }}</td>
                  <td>
                    <span v-if="row.method == 'withdraw' && row.rcpt_url">
                      <a
                        target="_blank"
                        :href="row.rcptfile"
                        class="badge badge-primary"
                        >Slip</a
                      >
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataLoaded: false,
      selectedStatus: "completed",
      items: [],
      statuses: ["approved"],
      searchForm: {
        year: "",
        month: "",
      },
      currentMonth: "",
      dataloaded: false,
      daysofMonth: 0,
      monthNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
    };
  },

  mounted() {
    var date = new Date();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();

    this.daysofMonth = parseInt(this.daysInMonth(month, year));

    // this.init();

    const d = new Date();
    this.currentMonth = d.getMonth() + 1;

    this.searchForm.year = d.getFullYear();
    this.searchForm.month = this.currentMonth;

    this.loadReqs();
  },

  computed: {
    years() {
      let years = [];
      let y = 2019;
      for (let i = y; i < y + 6; i++) {
        years.push(i);
      }
      return years;
    },
  },

  methods: {
    daysInMonth(month, year) {
      return new Date(year, month, 0).getDate();
    },
    loadReqs() {
      this.dataLoaded = false;
      this.saving();
      api.payout
        .status(
          this.selectedStatus,
          this.searchForm.month,
          this.searchForm.year
        )
        .then((res) => {
          this.items = res.data.data;
          this.dataLoaded = true;
          this.saved();
        });
    },
  },
};
</script>
