<template>
  <div>
    <div>
      <div class="card">
        <div class="card-body">
          <button @click="init()" class="mb-3 btn btn-primary">Reload</button>

          <!-- <pre>
            {{ rows }}
          </pre>-->

          <div class="table-responsive">
            <table class="table table-bordered">
              <thead class="thead-light">
                <tr>
                  <th>Day</th>
                  <th v-for="(val, i) in rows" :key="i">{{ val.day }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Returns</td>
                  <td v-for="(val, i) in rows" :key="i">
                    <span v-if="val.returns">{{ val.returns.payout_amount | currency }}</span>
                    <span v-else>{{ val.alt.payout_amount | currency }}</span>
                  </td>
                </tr>
                <tr>
                  <td>Sum</td>
                  <td
                    v-for="(val, i) in rows"
                    :key="i"
                    :class="{ 'bg-success text-white' : parseFloat(val.sum) > 1000 }"
                  >
                    <span>{{ val.sum | currency }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    user: Object
  },
  data() {
    return {
      dataloaded: false,
      rows: [],
      daysofMonth: 0
    };
  },

  mounted() {
    var date = new Date();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();

    this.daysofMonth = parseInt(this.daysInMonth(month, year));

    this.init();
  },

  methods: {
    init() {
      api.payout.userPayoutForecast(this.user.uuid).then(res => {
        this.rows = res.data.data;
        this.dataloaded = true;
      });
    },

    daysInMonth(month, year) {
      return new Date(year, month, 0).getDate();
    }
  }
};
</script>