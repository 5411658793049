<template>
  <div class>
    <div class="card">
      <div class="card-header border-0">
        <div class="row align-items-center">
          <div class="col">
            <h3 class="mb-0 text-uppercase">Pending cashback requests</h3>
          </div>
        </div>
      </div>
      <div class="table-responsive">
        <base-table thead-classes="thead-light" :data="rows">
          <template slot="columns">
            <th>Date</th>
            <th>Name</th>
            <th>Mobile</th>
            <th>Status</th>
            <th></th>
          </template>

          <template slot-scope="{ row }">
            <td>{{ row.created_at }}</td>
            <td>
              <router-link :to="`/investor/${row.user_uuid}`">{{
                row.user.name
              }}</router-link>
            </td>
            <td>{{ row.user.mobile }}</td>
            <td>
              <span class="badge badge-primary">{{ row.status }}</span>
            </td>
            <td>
              <div v-if="row.status == 'pending'">
                <span
                  class="badge badge-primary pointer"
                  @click="startProcessing(row)"
                  >Start processing</span
                >
                <span
                  class="badge badge-primary pointer"
                  @click="startReject(row)"
                  >Reject</span
                >
              </div>
            </td>
          </template>
        </base-table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: {
    user: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      rows: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (this.user) {
        console.log(this.user);
        axios.get("/cashbacks/get/all/" + this.user).then((res) => {
          this.rows = res.data.data;
        });
      } else {
        axios.get("/cashbacks/pending").then((res) => {
          this.rows = res.data.data;
        });
      }
    },
    startProcessing(row) {
      if (confirm("Confirm processing?")) {
        axios.post("/cashbacks/process", { uuid: row.uuid }).then(() => {
          this.init();
          this.$emit("init");
          this.$router.push(`/investor/${row.user_uuid}`);
        });
      }
    },
    startReject(row) {
      if (confirm("Confirm processing?")) {
        axios.post("/cashbacks/reject", { uuid: row.uuid }).then(() => {
          this.init();
          this.$emit("init");
          this.$router.push(`/investor/${row.user_uuid}`);
        });
      }
    },
  },
};
</script>