<template>
  <div>
    <base-header type="gradient-info" class="pb-6 pb-8 pt-5 pt-md-8">
      <button @click="addUserInit()" class="btn btn-primary text-uppercase mb-2">
        <small>Add user</small>
      </button>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="card">
        <div class="card-body">
          <table class="table table-bordered">
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Active</th>
              <th>Roles</th>
              <th>Super</th>
              <th></th>
            </tr>
            <tr v-for="user in users" :key="user.id">
              <td>{{ user.name }}</td>
              <td>{{ user.email }}</td>
              <td>{{ user.active }}</td>
              <td>{{ user.role }}</td>
              <td>{{ user.is_super }}</td>
              <th>
                <span @click="initEdit(user)" class="badge badge-primary pointer">Edit</span>
              </th>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <modal :show.sync="modals.addUser" modal-classes="modal-dialog-centered modal-lg">
      <div>
        <div class="content">
          <h3>Add/edit user</h3>
          <form action @submit.prevent="submit">
            <div class="row mb-3">
              <div class="col">
                <label for>Name</label>
                <input required type="text" v-model="form.name" class="form-control" />
              </div>
              <div class="col">
                <label for>Email</label>
                <input required type="email" v-model="form.email" class="form-control" />
              </div>
              <div class="col">
                <div>
                  <label for>Active</label>
                </div>
                <div>
                  <input type="checkbox" v-model="form.active" />
                </div>
              </div>
            </div>
            <div class="mb-3">
              <h5>Roles</h5>
              <div v-for="role in userRoles" :key="role.id">
                <label>
                  <input type="checkbox" :value="role.id" v-model="form.roles" />
                  {{ role.role }}
                </label>
              </div>
            </div>
            <button class="btn btn-primary" type="submit">Submit</button>
          </form>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modals: {
        addUser: false
      },
      form: {
        name: "",
        email: "",
        roles: [],
        active: false
      },
      userRoles: [],
      users: [],
      mode: 1
    };
  },
  mounted() {
    this.init();
    this.getRoles();
  },
  methods: {
    init() {
      axios.get("/users").then(res => {
        this.users = res.data.data;
      });
    },
    initEdit(row) {
      this.form = row;
      this.mode = 2;
      this.modals.addUser = true;
    },
    submit(e) {
      e.preventDefault();
      this.saving();
      if (this.mode == 1) {
        axios.post("/users", this.form).then(res => {
          this.init();
          this.modals.addUser = false;
          this.saved();
        });
      }
      if (this.mode == 2) {
        axios.put("/users/" + this.form.id, this.form).then(res => {
          this.init();
          this.modals.addUser = false;
          this.saved();
        });
      }
    },
    addUserInit() {
      this.modals.addUser = true;
    },
    getRoles() {
      axios.get("/roles").then(res => {
        this.userRoles = res.data.data;
      });
    }
  }
};
</script>