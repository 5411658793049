<template>
  <div>
    <login v-if="showLogin" @success="$router.push('/dashboard')" />
  </div>
</template>

<script>
import Login from "./Login";

export default {
  components: {
    Login
  },
  mounted() {
    if (this.$store.getters["auth/isLoggedIn"]) {
      this.$router.push("dashboard");
    } else {
      this.showLogin = true;
    }
    /* if (!this.$store.getters["auth/isLoggedIn"]) {
      window.location = process.env.VUE_APP_LOGIN_URL;
    } else {
      this.isLoggedIn = true;
    } */
  },
  data() {
    return {
      isLoggedIn: false,
      showLogin: false
    };
  }
};
</script>