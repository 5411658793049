<template>
  <div v-if="userLoaded" :key="frame">
    <base-header type="gradient-info" class="pb-6 pb-8 pt-5 pt-md-8">
      <h1 class="text-uppercase mb-4 text-white">
        <span>{{ user.name }} <span v-if="user.is_active == 0" class="badge badge-danger">Closed</span></span>
        <i
          @click="
            getUser();
            frame++;
          "
          class="fas fa-sync-alt pointer float-right"
        ></i>
      </h1>

      <!-- Card stats -->
      <div class="row mb-4">
        <div class="col-xl-4 col-lg-6">
          <stats-card
            title="Phone"
            type="gradient-red"
            :sub-title="user.mobile"
            class="mb-4 mb-xl-0"
          ></stats-card>
        </div>
        <div class="col-xl-4 col-lg-6">
          <stats-card
            title="Email"
            type="gradient-red"
            :sub-title="user.email"
            class="mb-4 mb-xl-0"
          ></stats-card>
        </div>
        <div class="col-xl-4 col-lg-6" v-if="user.profile">
          <stats-card
            title="Account number"
            type="gradient-red"
            :sub-title="user.profile.account_number"
            class="mb-4 mb-xl-0"
          ></stats-card>
        </div>
      </div>

      <div class="row mb-4">
        <div class="col-xl-4 col-lg-6" v-if="user.profile">
          <stats-card
            title="NID"
            type="gradient-red"
            :sub-title="user.profile.nid"
            class="mb-4 mb-xl-0"
          ></stats-card>
        </div>

        <div class="col-xl-4 col-lg-6" v-if="returns.pending">
          <stats-card
            title="Pending returns"
            type="gradient-red"
            :sub-title="returns.pending | currency"
            class="mb-4 mb-xl-0"
          ></stats-card>
        </div>
        <div class="col-xl-4 col-lg-6" v-if="returns.monthly">
          <stats-card
            title="monthly"
            type="gradient-red"
            :sub-title="returns.monthly | currency"
            class="mb-4 mb-xl-0"
          ></stats-card>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-xl-4 col-lg-6" v-if="user.active_bonds.length > 0">
          <stats-card
            title="Total active"
            type="gradient-red"
            :sub-title="totalActive | currency"
            class="mb-4 mb-xl-0"
          ></stats-card>
        </div>
        <div class="col-xl-4 col-lg-6" v-if="user.active_bonds.length > 0">
          <stats-card
            title="Total invested"
            type="gradient-red"
            :sub-title="totalInvested | currency"
            class="mb-4 mb-xl-0"
          ></stats-card>
        </div>
        <div class="col-xl-4 col-lg-6" v-if="user.active_bonds.length > 0">
          <stats-card
            title="Address"
            type="gradient-red"
            :sub-title="user.profile.address"
            class="mb-4 mb-xl-0"
          ></stats-card>
        </div>
      </div>

      <div class="row mb-4">
        <div class="col-xl-4 col-lg-6" v-if="lifeTimeStats.returns">
          <stats-card
            title="Lifetime returns"
            type="gradient-red"
            :sub-title="lifeTimeStats.returns"
            class="mb-4 mb-xl-0"
          ></stats-card>
        </div>
        <div class="col-xl-4 col-lg-6" v-if="lifeTimeStats.withdrawals">
          <stats-card
            title="Total withdrawn"
            type="gradient-red"
            :sub-title="lifeTimeStats.withdrawals"
            class="mb-4 mb-xl-0"
          ></stats-card>
        </div>
        <div class="col-xl-4 col-lg-6" v-if="lifeTimeStats.balance">
          <stats-card
            title="Balance"
            type="gradient-red"
            :sub-title="lifeTimeStats.balance"
            class="mb-4 mb-xl-0"
          ></stats-card>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-4 col-lg-6" v-if="sumNew">
          <stats-card
            title="Total new"
            type="gradient-red"
            :sub-title="`${sumNew}`"
            class="mb-4 mb-xl-0"
          ></stats-card>
        </div>

        <div class="col-xl-4 col-lg-6" v-if="reInvest">
          <stats-card
            title="Total reinvested"
            type="gradient-red"
            :sub-title="reInvest | currency"
            class="mb-4 mb-xl-0"
          ></stats-card>
        </div>

        <div
          class="col-xl-4 col-lg-6"
          v-if="user.profile && user.profile.nid_doc"
        >
          <div class="card card-stats mb-4 mb-xl-0">
            <div class="card-body">
              <div class="mb-4 mb-xl-0">
                <h5 class="card-title text-uppercase text-muted mb-0">
                  NID File
                </h5>
              </div>
              <span class="h2 font-weight-bold mb-0">
                <a :href="user.profile.nid_doc" target="_blank">open</a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid pt-5">
      <div class="mb-3">
        <form target="_blank" :action="user.statementlinkapi" method="POST">
          <button class="btn btn-primary" type="submit">Statement</button>
        </form>
        <!-- <a target="_blank" :href="user.statementlink" class="btn btn-primary">Statement</a> -->
      </div>

      <div class="mb-3">
        <Cashbacks :user="user" />
      </div>
      <div class>
        <div class>
          <pending-investment-requests
            @refresh="getUser()"
            v-if="user.pending_bonds.length > 0"
            :user="user"
            class="mb-4"
          ></pending-investment-requests>

          <approved-investment-requests
            @refresh="getUser()"
            v-if="user.approved_bonds.length > 0"
            :user="user"
            class="mb-4"
          ></approved-investment-requests>

          <pending-payment-investments
            @refresh="getUser()"
            v-if="user.payment_bonds.length > 0"
            :user="user"
            class="mb-4"
          ></pending-payment-investments>

          <paid-investment-requests
            @refresh="getUser()"
            v-if="user.paid_bonds.length > 0"
            :user="user"
            class="mb-4"
          ></paid-investment-requests>
        </div>
      </div>

      <div class="mt-5 mb-5 mb-xl-0">
        <tabs>
          <tab-pane
            :title="`Active investments (${user.active_bonds.length})`"
            v-if="user.active_bonds.length > 0"
          >
            <investments :user="user" class="mb-5"></investments>
          </tab-pane>
          <tab-pane
            :title="`Matured investments (${user.matured_bonds.length})`"
            v-if="user.matured_bonds.length > 0"
          >
            <InvestmentsMatured :user="user" class="mb-5"></InvestmentsMatured>
          </tab-pane>
          <tab-pane title="Payout Requests">
            <payout-requests
              @refresh="getUser()"
              style="margin-bottom: 30px"
              :user="user"
            ></payout-requests>
          </tab-pane>
          <tab-pane title="Documents">
            <documents
              @refresh="getUser()"
              style="margin-bottom: 30px"
              :user="user"
            ></documents>
          </tab-pane>
          <!-- <tab-pane title="Forecast">
            <payout-forecast :user="user"></payout-forecast>
          </tab-pane>-->
        </tabs>
      </div>
    </div>
  </div>
</template>

<script>
import Investments from "./Investor/Investments";
import InvestmentsMatured from "./Investor/InvestmentsMatured";
import PendingInvestmentRequests from "./Investor/PendingInvestmentRequests";
import ApprovedInvestmentRequests from "./Investor/ApprovedInvestmentRequests";
import PaidInvestmentRequests from "./Investor/PaidInvestmentRequests";
import PendingPaymentInvestments from "./Investor/PendingPaymentInvestments";
import PayoutRequests from "./Investor/PayoutRequests";
import PayoutForecast from "./Investor/PayoutForecast";
import Documents from "./Investor/Documents";
import Cashbacks from "./Investor/Cashbacks";
export default {
  components: {
    Investments,
    PendingInvestmentRequests,
    ApprovedInvestmentRequests,
    PaidInvestmentRequests,
    PendingPaymentInvestments,
    PayoutRequests,
    PayoutForecast,
    Documents,
    InvestmentsMatured,
    Cashbacks,
  },
  data() {
    return {
      frame: 1,
      userLoaded: false,
      user: {},
      returns: { pending: null, monthly: null },
      lifeTimeStats: {},
    };
  },

  mounted() {
    this.getUser();
  },

  computed: {
    totalActive() {
      let total = 0;
      if (this.user.active_bonds.length > 0) {
        this.user.active_bonds.forEach((element) => {
          total += element.invested_amount;
        });
      }
      return total;
    },

    sumNew() {
      let sum = 0;
      this.user.active_bonds.forEach((element) => {
        if (element.source == "new") {
          sum += element.invested_amount;
        }
      });
      this.user.matured_bonds.forEach((element) => {
        if (element.source == "new") {
          sum += element.invested_amount;
        }
      });
      return sum;
    },

    reInvest() {
      let sum = 0;
      this.user.active_bonds.forEach((element) => {
        if (element.source == "reinvest") {
          sum += element.invested_amount;
        }
      });
      this.user.matured_bonds.forEach((element) => {
        if (element.source == "reinvest") {
          sum += element.invested_amount;
        }
      });
      return sum;
    },

    totalInvested() {
      return parseFloat(this.sumNew) + parseFloat(this.reInvest);
    },
  },

  methods: {
    getUser() {
      this.saving();
      api.invest.userProfile(this.$route.params.uuid).then((res) => {
        this.user = res.data.data;
        this.userLoaded = true;
        this.saved();
        this.getUserPayoutSum();
        this.getUserLifeTimeStats();
      });
      api.bonds.getProjects().then((res) => {
        this.projects = res.data.data;
      });
    },

    getUserPayoutSum() {
      api.invest.pendingPayouts(this.user.uuid).then((res) => {
        this.returns.pending = res.data.data;
      });
      api.invest.monthlyPayout(this.user.uuid).then((res) => {
        this.returns.monthly = res.data.data;
      });
    },

    getUserLifeTimeStats() {
      api.payout.userLifeTimeStats(this.$route.params.uuid).then((res) => {
        this.lifeTimeStats = res.data.data;
      });
    },
  },
};
</script>