<template>
  <div class>
    <div class="card">
      <div class="card-header border-0">
        <div class="row align-items-center">
          <div class="col">
            <h3 class="mb-0 text-uppercase">Paid requests</h3>
          </div>
        </div>
      </div>
      <div class="table-responsive">
        <base-table thead-classes="thead-light" :data="user.paid_bonds">
          <template slot="columns">
            <th>Date</th>
            <th>Amount</th>
            <th></th>
          </template>

          <template slot-scope="{row}">
            <td>{{row.created_at}}</td>
            <td>{{row.invested_amount | currency}}</td>
            <td>
              <span
                class="badge badge-primary pointer"
                @click="projectsModal = true; selectedInvestmentUuid = row.uuid"
              >assign project</span>
            </td>
          </template>
        </base-table>
      </div>
    </div>

    <modal :show.sync="projectsModal" modal-classes="modal-dialog-centered modal-lg">
      <template slot="header">
        <h5 class="modal-title" id="exampleModalLabel">Projects</h5>
      </template>
      <div>
        <div class="table-responsive">
          <base-table thead-classes="thead-light" :data="projects">
            <template slot="columns">
              <th>Project</th>
              <th>Total monthly</th>
              <th>Balance</th>
              <th></th>
            </template>

            <template slot-scope="{row}">
              <td>{{row.name}}</td>
              <td>{{row.monthly_return}}</td>
              <td>{{ getBalance(row.monthly_return, getProjectInvestTotal(row.investments)) }}</td>
              <td>
                <span class="badge badge-primary pointer" @click="assignProject(row.uuid)">Assign</span>
              </td>
            </template>
          </base-table>
        </div>
      </div>
      <template slot="footer">
        <base-button type="secondary" @click="projectsModal = false">Close</base-button>
      </template>
    </modal>
  </div>
</template>

<script>
export default {
  props: {
    user: Object
  },
  data() {
    return {
      projects: [],
      projectsModal: false,
      selectedInvestmentUuid: null
    };
  },

  mounted() {
    api.bonds.getProjects().then(res => {
      this.projects = res.data.data;
    });
  },
  methods: {
    getBalance(total, balance) {
      return parseInt(total) - parseInt(balance);
    },

    getProjectInvestTotal(investments) {
      let sum = 0;
      investments.forEach(element => {
        sum += parseInt(element.monthly_return);
      });
      return sum;
    },

    assignProject(projectUuid) {
      this.saving();
      api.invest
        .assignProject(this.selectedInvestmentUuid, {
          bondproject_uuid: projectUuid
        })
        .then(res => {
          this.projectsModal = false;
          this.saved();
          this.$emit("refresh");
        });
    }
  }
};
</script>