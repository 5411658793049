<template>
  <div>
    <base-header type="gradient-info" class="pb-6 pb-8 pt-5 pt-md-8">
      <button
        v-for="status in statuses"
        :key="status"
        @click="loadReqs(status); selectedStatus = status"
        class="btn btn-primary text-uppercase mb-2"
        :class="{ 'btn-success': (status == selectedStatus) }"
      >
        <small>{{ status }}</small>
      </button>

      <div class="row mt-3">
        <div class="col-xl-4 col-lg-6">
          <div class="card card-stats mb-4 mb-xl-0" show-footer-line="true">
            <div class="card-body mb-0">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">Total investors</h5>
                  <span class="h2 font-weight-bold mb-0">{{ items.investors }}</span>
                </div>
              </div>
              <p class="mt-3 mb-0 text-sm"></p>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-6">
          <div class="card card-stats mb-4 mb-xl-0" show-footer-line="true">
            <div class="card-body mb-0">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">Total investment</h5>
                  <span class="h2 font-weight-bold mb-0">{{ items.investedAmount }}</span>
                </div>
              </div>
              <p class="mt-3 mb-0 text-sm"></p>
            </div>
          </div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <!-- <base-table thead-classes="thead-light" :data="items.investments">
              <template slot="columns">
                <th>Date</th>
                <th>Name</th>
                <th>Email</th>
                <th>Amount</th>
                <th>Status</th>
              </template>

              <template slot-scope="{row}">
                <th scope="row">{{row.cdt}}</th>
                <td class="text-uppercase">
                  <router-link :to="'/investor/' + row.user.uuid">{{row.user.name}}</router-link>
                </td>
                <td>{{row.user.email}}</td>
                <td>{{row.invested_amount}}</td>
                <td>
                  <badge type="info">{{row.status}}</badge>
                </td>
              </template>
            </base-table>-->

            <table class="table table-bordered">
              <thead class="thead-light">
                <tr>
                  <th></th>
                  <th>Name</th>
                  <th>Mobile</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Source</th>
                  <th>Active</th>
                  <th>Mature</th>
                  <th>Days to mature</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in items.investments" :key="row.uuid">
                  <td width="2%">{{ index+1 }}</td>
                  <td class="text-uppercase">
                    <router-link :to="'/investor/' + row.user.uuid">{{row.user.name}}</router-link>
                  </td>
                  <td>{{row.user.mobile}}</td>
                  <td>{{row.invested_amount}}</td>
                  <td>
                    <badge type="success">{{row.status}}</badge>
                  </td>
                  <td>
                    <badge type="info">{{row.source}}</badge>
                  </td>
                  <td>{{row.adt}}</td>
                  <td>{{row.mdt}}</td>
                  <td>{{row.days_to_human}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedStatus: "pending",
      items: [],
      statuses: ["pending", "approved", "payment", "paid", "active"]
    };
  },

  mounted() {
    this.loadReqs(this.selectedStatus);
  },

  methods: {
    loadReqs(selectedStatus) {
      this.saving();
      api.invest.status(selectedStatus).then(res => {
        this.items = res.data.data;
        this.saved();
      });
    }
  }
};
</script>
