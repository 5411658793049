<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <!-- Card stats -->

      <div class="row">
        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="Total investors"
            type="gradient-red"
            :sub-title="highLights.totalInvestors"
            icon="ni ni-single-02"
            class="mb-4 mb-xl-0"
          >
            <!-- <template slot="footer">
              <span class="text-success mr-2">
                <i class="fa fa-arrow-up"></i> 3.48%
              </span>
              <span class="text-nowrap">Since last month</span>
            </template>-->
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="Total investment"
            type="gradient-info"
            :sub-title="highLights.totalInvestedAmount"
            icon="ni ni-chart-bar-32"
            class="mb-4 mb-xl-0"
          >
            <!-- <template slot="footer">
              <span class="text-success mr-2">
                <i class="fa fa-arrow-up"></i> 54.8%
              </span>
              <span class="text-nowrap">Since last month</span>
            </template>-->
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="Pending returns"
            type="gradient-orange"
            :sub-title="highLights.pendingTotalReturns"
            icon="ni ni-chart-pie-35"
            class="mb-4 mb-xl-0"
          >
            <!-- <template slot="footer">
              <span class="text-success mr-2">
                <i class="fa fa-arrow-up"></i> 12.18%
              </span>
              <span class="text-nowrap">Since last month</span>
            </template>-->
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="Returns forcast"
            type="gradient-green"
            :sub-title="highLights.returnsForecastForTheMonth"
            icon="ni ni-money-coins"
            class="mb-4 mb-xl-0"
          >
            <!-- <template slot="footer">
              <span class="text-danger mr-2">
                <i class="fa fa-arrow-down"></i> 5.72%
              </span>
              <span class="text-nowrap">Since last month</span>
            </template>-->
          </stats-card>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="Total reinvested"
            type="gradient-red"
            :sub-title="highLights.totalReinvested"
            icon="ni ni-active-40"
            class="mb-4 mb-xl-0"
          >
            <!-- <template slot="footer">
              <span class="text-success mr-2">
                <i class="fa fa-arrow-up"></i> 3.48%
              </span>
              <span class="text-nowrap">Since last month</span>
            </template>-->
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="Total referral"
            type="gradient-info"
            :sub-title="highLights.totalReferral"
            icon="ni ni-chart-bar-32"
            class="mb-4 mb-xl-0"
          >
            <!-- <template slot="footer">
              <span class="text-success mr-2">
                <i class="fa fa-arrow-up"></i> 54.8%
              </span>
              <span class="text-nowrap">Since last month</span>
            </template>-->
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="Referral pay"
            type="gradient-orange"
            :sub-title="highLights.totalReferralPay"
            icon="ni ni-chart-pie-35"
            class="mb-4 mb-xl-0"
          >
            <!-- <template slot="footer">
              <span class="text-success mr-2">
                <i class="fa fa-arrow-up"></i> 12.18%
              </span>
              <span class="text-nowrap">Since last month</span>
            </template>-->
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="New Investment"
            type="gradient-green"
            :sub-title="highLights.totalNewInvestment"
            icon="ni ni-money-coins"
            class="mb-4 mb-xl-0"
          >
            <!-- <template slot="footer">
              <span class="text-danger mr-2">
                <i class="fa fa-arrow-down"></i> 5.72%
              </span>
              <span class="text-nowrap">Since last month</span>
            </template>-->
          </stats-card>
        </div>
      </div>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-8 mb-5 mb-xl-0">
          <card type="default" header-classes="bg-transparent">
            <div slot="header" class="row align-items-center">
              <div class="col">
                <h6 class="text-light text-uppercase ls-1 mb-1">Overview</h6>
                <h5 class="h3 text-white mb-0">Investment value</h5>
              </div>
              <div class="col">
                <ul class="nav nav-pills justify-content-end">
                  <li class="nav-item mr-2 mr-md-0">
                    <a
                      class="nav-link py-2 px-3"
                      href="#"
                      :class="{ active: bigLineChart.activeIndex === 0 }"
                      @click.prevent="initBigChart(0)"
                    >
                      <span class="d-none d-md-block">Month</span>
                      <span class="d-md-none">M</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <line-chart
              :height="350"
              ref="bigChart"
              :chart-data="bigLineChart.chartData"
              :extra-options="bigLineChart.extraOptions"
            ></line-chart>
          </card>
        </div>

        <div class="col-xl-4">
          <card header-classes="bg-transparent">
            <div slot="header" class="row align-items-center">
              <div class="col">
                <h6 class="text-uppercase text-muted ls-1 mb-1">Performance</h6>
                <h5 class="h3 mb-0">Total payouts</h5>
              </div>
            </div>

            <bar-chart
              v-if="showBarChart"
              :height="350"
              ref="barChart"
              :chart-data="redBarChart.chartData"
            ></bar-chart>
          </card>
        </div>
      </div>
      <!-- End charts-->

      <div class="mt-4">
        <form target="_blank" :action="dailyReportUrl" method="GET" class="m-0">
          <div class="d-flex">
            <input
              type="date"
              name="date"
              required
              class="form-control mr-3"
              style="width: 200px"
            />
            <button class="btn btn-primary" type="submit">Daily report</button>
          </div>
        </form>
      </div>

      <!--Tables-->
      <div class="row mt-4">
        <div class="col-xl-8 mb-5 mb-xl-0">
          <CashbackRequests class="mb-3" />
          <invest-requests></invest-requests>
        </div>
        <div class="col-xl-4">
          <payout-requests></payout-requests>
        </div>
      </div>
      <!--End tables-->
    </div>
  </div>
</template>
<script>
// Charts
import * as chartConfigs from "@/components/Charts/config";
import LineChart from "@/components/Charts/LineChart";
import BarChart from "@/components/Charts/BarChart";

// Tables
import PayoutRequests from "./Dashboard/PayoutRequests";
import InvestRequests from "./Dashboard/InvestRequests";
import CashbackRequests from "./Dashboard/CashbackRequests";

export default {
  components: {
    LineChart,
    BarChart,
    InvestRequests,
    PayoutRequests,
    CashbackRequests,
  },
  data() {
    return {
      dailyReportUrl: "",
      lifeTimeStats: {},
      showBarChart: false,
      highLights: [],
      bigLineChart: {
        allData: [
          [0, 20, 10, 30, 15, 40, 20, 60, 60],
          [0, 20, 5, 25, 10, 30, 15, 40, 40],
        ],
        activeIndex: 0,
        chartData: {
          datasets: [],
          labels: [],
        },
        extraOptions: chartConfigs.blueChartOptions,
      },
      redBarChart: {
        chartData: {
          labels: [],
          datasets: [
            {
              label: "Payout",
              data: [25, 20, 30, 22, 17, 29],
            },
          ],
        },
      },
    };
  },
  methods: {
    initBigChart(index) {
      let chartData = {
        datasets: [
          {
            label: "Investment",
            data: this.bigLineChart.allData[index],
          },
        ],
        labels: this.highLights.investmentHistory["months"], // ["May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
      };
      this.bigLineChart.chartData = chartData;
      this.bigLineChart.activeIndex = index;
    },

    getPendingTotal() {
      api.dashboard.highlights().then((res) => {
        this.highLights = res.data.data;
        this.bigLineChart.allData[0] =
          this.highLights.investmentHistory["values"];
        this.initBigChart(0);
        this.initredBarChart();
        // this.getLifeTimeStats();
      });
    },

    initredBarChart() {
      this.redBarChart.chartData.labels =
        this.highLights.payoutHistory["months"];
      this.redBarChart.chartData.datasets[0].data =
        this.highLights.payoutHistory["values"];
      this.showBarChart = true;
    },

    getLifeTimeStats() {
      api.dashboard.lifeTimeStats().then((res) => {
        this.lifeTimeStats = res.data.data;
      });
    },
  },
  mounted() {
    this.getPendingTotal();

    this.dailyReportUrl = "https://apartlease.com/api/pdf/daily-report";
    if (process.env.VUE_APP_ENV == "local") {
      this.dailyReportUrl = "http://apls.test/api/pdf/daily-report";
    }
  },
};
</script>
<style></style>
