<template>
  <div>
    <div class="card">
      <div class="card-header border-0">
        <div class="row align-items-center">
          <div class="col-4">
            <h3 class="mb-0 text-uppercase">Documents</h3>
          </div>
          <div class="col-8 text-right"></div>
        </div>
      </div>
      <div class="card-body">
        <div class="rounded border p-3">
          <h4>Upload document</h4>
          <form @submit.prevent="submit">
            <div class="row">
              <div class="col-md">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Document title"
                  required
                  v-model="form.title"
                />
              </div>
              <div class="col-md">
                <label>
                  <input type="file" id="file" ref="file" required v-on:change="handleFileUpload()" />
                </label>
              </div>
              <div class="col-md">
                <button class="btn btn-primary" type="submit">Submit</button>
              </div>
            </div>
          </form>
        </div>

        <table class="table table-bordered mt-5">
          <thead class="thead-light">
            <tr>
              <th>Title</th>
              <th>File</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in docs" :key="index">
              <td>{{ row.title }}</td>
              <td>
                <a :href="row.doc_url" target="_blank" class="btn btn-primary btn-sm">Open</a>
                <button class="btn btn-danger btn-sm" @click="deleteFile(row.uuid)">Delete</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    user: Object
  },
  data() {
    return {
      docs: [],
      form: {
        title: "",
        remarks: "",
        file: ""
      }
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      api.investor.getInvestorDocs(this.user.uuid).then(res => {
        this.docs = res.data.data;
      });
    },
    handleFileUpload() {
      this.form.file = this.$refs.file.files[0];
    },
    submit(e) {
      e.preventDefault();
      let formData = new FormData();
      formData.append("title", this.form.title);
      formData.append("file", this.form.file);
      formData.append("remarks", this.form.remarks);
      api.investor.uploadInvestorDoc(this.user.uuid, formData).then(res => {
        // this.$emit("refresh");
        this.init();
      });
    },

    deleteFile(fileUuid) {
      if (confirm("Confirm delete?")) {
        api.investor.deleteInvestorDoc(this.user.uuid, fileUuid).then(res => {
          this.init();
        });
      }
    }
  }
};
</script>